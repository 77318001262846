// Import CSS - will be purged via purgecss
import './bootstrap.css';
import './main.css';
import './alert.css';

// Custom global javascript functions
window.atf6 = {
  docReady: function (fn) {
    // see if DOM is already available
    if (
      document.readyState === 'complete' ||
      document.readyState === 'interactive'
    ) {
      // call on next available tick
      setTimeout(fn, 1);
    } else {
      document.addEventListener('atf6LoadComplete', fn);
    }
  },
};
